<script lang="ts">
  import { onMount } from 'svelte';

  let debounce;
  let debouncedSearch: any

  // Props
  export let placeholder: string = 'Job title';
  export let initialSuggestions: any[] = [];
  export let searchFunction: (query: string) => Promise<any[]>;
  export let select: (item: any) => any;
  export let noSuggestion: (query: string) => any;

  // Data
  let inputValue: any = '';
  let suggestions: any[] = [];
  let isFocused: boolean = false;
  let isLoading: boolean = false;
  let showSuggestions: boolean = false;

  // Helpers
  async function loadDebounce() {
    const mod = await import('debounce');
    debounce = mod.default;

    debouncedSearch = debounce(async (query: string) => {
      isLoading = true;
      suggestions = await searchFunction(query);
      isLoading = false;
      showSuggestions = true;
    }, 800);
  }

  function getDisplayName(suggestion: { name_en?: string, name?: string }): string {
    return suggestion.name_en || suggestion.name || suggestion.toString();
  }

  // Events
  function handleInput() {
    if(debouncedSearch){
      if (inputValue.length > 0) {
        debouncedSearch(inputValue);
      } else {
        debouncedSearch.cancel();
        suggestions = initialSuggestions;
      }
    }
  }

  function handleFocus() {
    isFocused = true;
    if (initialSuggestions.length > 0 || suggestions.length > 0) {
      if(inputValue.length === 0){
        suggestions = initialSuggestions;
      }
      showSuggestions = true;
    }
  }

  function handleBlur() {
    setTimeout(() => {
      isFocused = false;
      showSuggestions = false;
    }, 200);
  }

  function handleSuggestionClick(suggestion: any) {
    inputValue = getDisplayName(suggestion);
    showSuggestions = false;
    select(suggestion);
  }

  function handleNoSuggestionClick() {
    noSuggestion(inputValue);
  }

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (suggestions.length > 0) {
        handleNoSuggestionClick();
      }
    }
  }

  // lifecycle
  onMount(() => {
    suggestions = initialSuggestions;
    loadDebounce();
  });
</script>

<div class="w-full md:w-fit">
  <div class="relative">
    <input
      type="text"
      {placeholder}
      bind:value={inputValue}
      on:input={handleInput}
      on:focus={handleFocus}
      on:blur={handleBlur}
      on:keydown={handleKeydown}
      class="py-2 pr-4 pl-10 border border-gray-3 rounded-lg transition duration-300 ease-in-out
        focus:outline-none focus:ring-0 focus:border-dark-gray focus:shadow-lg focus:shadow-blue-100
        w-full md:w-fit
      "
    />
    <svg class="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>

    <ul class="w-full bg-white border border-t-0 border-dark-gray rounded-b-md z-10
        absolute top-[calc(100%-6px)]
      "
      class:opacity-0={!showSuggestions || suggestions.length === 0}
      class:invisible={!showSuggestions || suggestions.length === 0}
      class:opacity-100={showSuggestions && suggestions.length > 0}
      class:visible={showSuggestions && suggestions.length > 0}
    >
      {#each suggestions as suggestion, index}
        <li>
          <button
            type="button"
            on:click={() => handleSuggestionClick(suggestion)}
            class="max-w-full w-full py-2 px-4 flex justify-between items-center cursor-pointer
              whitespace-nowrap bg-opacity-60 hover:bg-opacity-100 hover:bg-blue-4
            "
            class:rounded-b-lg={index === suggestions.length - 1}
            title={getDisplayName(suggestion)}
          >
            <span class="text-ellipsis overflow-hidden text-left font-medium">{getDisplayName(suggestion)}</span>
            <svg class="min-w-min" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.15 7.00001H1C0.716667 7.00001 0.479167 6.90418 0.2875 6.71251C0.0958333 6.52085 0 6.28335 0 6.00001C0 5.71668 0.0958333 5.47918 0.2875 5.28751C0.479167 5.09585 0.716667 5.00001 1 5.00001H12.15L9.3 2.15001C9.1 1.95001 9.00417 1.71668 9.0125 1.45001C9.02083 1.18335 9.11667 0.950012 9.3 0.750012C9.5 0.550012 9.7375 0.445845 10.0125 0.437512C10.2875 0.429178 10.525 0.525012 10.725 0.725012L15.3 5.30001C15.4 5.40001 15.4708 5.50835 15.5125 5.62501C15.5542 5.74168 15.575 5.86668 15.575 6.00001C15.575 6.13335 15.5542 6.25835 15.5125 6.37501C15.4708 6.49168 15.4 6.60001 15.3 6.70001L10.725 11.275C10.525 11.475 10.2875 11.5708 10.0125 11.5625C9.7375 11.5542 9.5 11.45 9.3 11.25C9.11667 11.05 9.02083 10.8167 9.0125 10.55C9.00417 10.2833 9.1 10.05 9.3 9.85001L12.15 7.00001Z" fill="#6E8D98"/>
            </svg>
          </button>
        </li>
      {/each}
    </ul>
    {#if isFocused && inputValue && !isLoading && suggestions.length === 0}
      <button
        on:click={handleNoSuggestionClick}
        class="absolute right-2 top-1/2 transform -translate-y-1/2 py-1 px-2"
      >
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.15 7.00001H1C0.716667 7.00001 0.479167 6.90418 0.2875 6.71251C0.0958333 6.52085 0 6.28335 0 6.00001C0 5.71668 0.0958333 5.47918 0.2875 5.28751C0.479167 5.09585 0.716667 5.00001 1 5.00001H12.15L9.3 2.15001C9.1 1.95001 9.00417 1.71668 9.0125 1.45001C9.02083 1.18335 9.11667 0.950012 9.3 0.750012C9.5 0.550012 9.7375 0.445845 10.0125 0.437512C10.2875 0.429178 10.525 0.525012 10.725 0.725012L15.3 5.30001C15.4 5.40001 15.4708 5.50835 15.5125 5.62501C15.5542 5.74168 15.575 5.86668 15.575 6.00001C15.575 6.13335 15.5542 6.25835 15.5125 6.37501C15.4708 6.49168 15.4 6.60001 15.3 6.70001L10.725 11.275C10.525 11.475 10.2875 11.5708 10.0125 11.5625C9.7375 11.5542 9.5 11.45 9.3 11.25C9.11667 11.05 9.02083 10.8167 9.0125 10.55C9.00417 10.2833 9.1 10.05 9.3 9.85001L12.15 7.00001Z" fill="#1E95F7"/>
        </svg>
      </button>
    {/if}
  </div>
</div>
