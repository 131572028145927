<script lang="ts">
  import { onMount } from 'svelte';
  import Autocomplete from './Autocomplete.svelte';

  let initialSuggestions: any[] = [];

  const searchFunction = async (query: string) => {
    try {
      const response = await fetch(`/astroapi/home/autocomplete-suggestions?query=${encodeURIComponent(query)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching search results:', error);
      return [];
    }
  };

  const fetchInitialSuggestions = async () => {
    try {
      const response = await fetch('/astroapi/home/autocomplete-suggestions');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      initialSuggestions = data;
    } catch (error) {
      console.error('Error fetching initial suggestions:', error);
    }
  };

  const select = (item: any) => {
    if (item.link) {
      window.location.href = item.link;
    } else {
      const keyword = item.name || item.name_en || '';
      window.location.href = `/remote-jobs/${encodeURIComponent(keyword)}`;
    }
  };

  const noSuggestion = (query: string) => {
    window.location.href = `/remote-jobs/${encodeURIComponent(query)}`;
  };

  // setup
  onMount(() => {
    fetchInitialSuggestions();
  });
</script>

<Autocomplete
  {searchFunction}
  {select}
  {noSuggestion}
  {initialSuggestions}
  placeholder="Job title"
/>
